import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, Divider, Grid, Segment,
} from 'semantic-ui-react';
import EntrepreneurLogo from '!svg-react-loader!../../images/logos/logo-entrepreneur.svg?name=Entrepreneur';
import ExaminerLogo from '!svg-react-loader!../../images/logos/logo-examiner.svg?name=Examiner';
import ForbesLogo from '!svg-react-loader!../../images/logos/logo-forbes.svg?name=Forbes';
import FoxBusinessLogo from '!svg-react-loader!../../images/logos/logo-fox-business.svg?name=FoxBusiness';
import HuffpostLogo from '!svg-react-loader!../../images/logos/logo-huffpost.svg?name=Huffpost';
import TheMotelyFoolLogo from '!svg-react-loader!../../images/logos/logo-the-motley-fool.svg?name=TheMotelyFool';

const Hero = props => props.children;
const Featured = props => props.children;
const Body = props => props.children;
const PreSidebar = props => props.children;
const PostSidebar = props => props.children;

Hero.displayName = 'Hero';
Featured.displayName = 'Featured';
Body.displayName = 'Body';
PreSidebar.displayName = 'PreSidebar';
PostSidebar.displayName = 'PostSidebar';

const Wrapper = (props) => {
    const { children } = props;
    const sections = {
        hero: React.Children.toArray(children).filter(child => child.type.displayName === 'Hero'),
        featured: React.Children.toArray(children).filter(child => child.type.displayName === 'Featured'),
        body: React.Children.toArray(children).filter(child => child.type.displayName === 'Body'),
        presidebar: React.Children.toArray(children).filter(child => child.type.displayName === 'PreSidebar'),
        postsidebar: React.Children.toArray(children).filter(child => child.type.displayName === 'PostSidebar'),
    };


    return (
        <>
            <Container fluid className="hero">
                {sections.hero}
            </Container>
            <Container className="as-featured-on">
                <Grid>
                    <Grid.Row centered>
                        <Segment basic>
                            <span>As&nbsp;Featured&nbsp;On:</span>
                            <EntrepreneurLogo />
                            <ExaminerLogo />
                            <ForbesLogo />
                            <FoxBusinessLogo />
                            <HuffpostLogo />
                            <TheMotelyFoolLogo />
                        </Segment>
                    </Grid.Row>
                </Grid>
            </Container>
            <Divider fitted />
            {sections.featured}
            <Divider hidden />
            <Container>
                <Grid stackable columns={2}>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            {sections.body}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {sections.presidebar}
                            <Divider hidden />
                            {sections.postsidebar}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </>
    );
};

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export {
    Hero, Featured, Body, PreSidebar, PostSidebar, Wrapper,
};
