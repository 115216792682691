import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'semantic-ui-react';
import { Link } from 'gatsby';

const WordPressPostCard = (props) => {
    const { data, excerpt } = props;
    return (
        <Card as={Link} to={data.slug}>
            <Image src={data.medium_image.url} wrapped ui={false} />
            <Card.Content>
                <Card.Header>
                    {data.title}
                </Card.Header>
                { excerpt ? <Card.Description><div dangerouslySetInnerHTML={{ __html: data.excerpt }} /></Card.Description> : null }
            </Card.Content>
        </Card>
    );
};

WordPressPostCard.propTypes = {
    data: PropTypes.shape({
        medium_image: PropTypes.object,
        slug: PropTypes.string,
        title: PropTypes.string,
    }).isRequired,
    excerpt: PropTypes.bool,
};

WordPressPostCard.defaultProps = {
    excerpt: false,
};

export default WordPressPostCard;
