import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Header, Image, Feed } from 'semantic-ui-react';

const WordPressRelatedLink = (props) => {
    const {
        data: { slug, title, small_image: { url } },
    } = props;

    return (
        <Feed.Event
            as="a"
            href={slug}
            image={url}
            content={title}
        />
    );
};

WordPressRelatedLink.propTypes = {
    data: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        small_image: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

export default WordPressRelatedLink;
